import { getEnv, inBrowser } from '@kit/utils/EnvironmentHelper'
import { getRecaptchaObject } from '@kit/utils/APIV1' ////ALEX-RECAPTCHA-2025



export const googleRecaptchaConfig = () => {
  return { 
    siteKey: getEnv("GOOGLE_RECAPTCHA_SITE_KEY"), 
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  }
}

//ALEX-RECAPTCHA-2025
const getRecaptchaToken = async (actionName) => {
  if(inBrowser) {

    const { executeRecaptcha, recaptchaLoaded } = getRecaptchaObject()
    //(optional) Wait until recaptcha has been loaded.
    await recaptchaLoaded()

    // Execute reCAPTCHA with action e.g. "login", "contact_us"
    const token = await executeRecaptcha(actionName)
    //Do stuff with the received token.
    return token
  }
}

//ALEX-RECAPTCHA-2025
//Use this function to add the recaptcha token to a body of 
//data before submitting it to an endpoint that requires a recaptch token
export const recaptchaTokenize = async(dataObj, recaptchaActionName) => {

  if(inBrowser) {
    const token = await getRecaptchaToken(recaptchaActionName)
    dataObj["recaptchaToken"] = token
  }

  return dataObj

}